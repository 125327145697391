import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button, Card, Col, Modal, Row, Tooltip } from "antd";
import { HiDocumentReport } from "react-icons/hi";
import { MdAgriculture } from "react-icons/md";
import ReactCardFlip from 'react-card-flip';
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import arkansas from "../../images/workshop/arkansas-icon.png";
import illinois from "../../images/workshop/illinois-icon.png";
import indiana from "../../images/workshop/indiana-icon.png";
import iowa from "../../images/workshop/iowa-icon.png";
import louisiana from "../../images/workshop/louisiana-icon.png";
import mississippi from "../../images/workshop/mississippi-icon.png";
import missouri from "../../images/workshop/missouri-icon.png";
import ohio from "../../images/workshop/ohio-icon.png";
import virtualWorkshop from "../../images/workshop/virtual_workshop.png";
import workshopPhoto1 from "../../images/workshop/workshop_photo_1.png";
import mwae from "../../images/workshop/mwae_logo.png";
import msfg from "../../images/workshop/msfg_logo.png";
import './style.css';

function Workshops() {
	const [showWorkshopsIL, setShowWorkshopsIL] = useState(false);
	const [showWorkshopsMO, setShowWorkshopsMO] = useState(false);
	const [showWorkshopsMS, setShowWorkshopsMS] = useState(false);
	const [showWorkshopsVR, setShowWorkshopsVR] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [fileToOpen, setFileToOpen] = useState('');

	const workshops = {
		MS: [
			{
				title: "Pontotoc",
				location: "MSU Extension - Pontotoc County",
				address: "402 C.J. Hardin Jr. Drive, Pontotoc, MS 38863",
				coordinates: { lat: 34.25622906247503, lng: -89.02503027440059 },
				date: "11/14/2023",
				time: "9:30 AM – 12:00 PM CST",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-c772ec4b71764c5a9c673e1bf48bc64e"
			},
			{
				title: "Stoneville",
				location: "MSU Delta Research and Extension Center",
				address: "82 Stoneville Rd, Stoneville, MS 38776",
				coordinates: { lat: 33.42297732890887, lng: -90.90924661675558 },
				date: "11/16/2023",
				time: "9:30 AM – 12:00 PM CST",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-aa02e0bc934848ff919caaadf769f299"
			},
			{
				title: "Onward",
				location: "Theodore Roosevelt National Wildlife Refuge - Visitor Center",
				address: "5068 US-61, Rolling Fork, MS 39159",
				coordinates: { lat: 32.70462726914907, lng: -90.93182795432807 },
				date: "11/27/2023",
				time: "9:30 AM – 12:00 PM CST",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-fbf4bb309f28498d93e5114417952b6e"
			},
			{
				title: "Tunica",
				location: "Tunica County Museum",
				address: "1 Museum Blvd, Tunica, MS 38676",
				coordinates: { lat: 34.72825985749033, lng: -90.36801117219258 },
				date: "11/29/2023",
				time: "9:30 AM – 12:00 PM CST",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-bfe397240c534cb085091571745952f2"
			}
		],
		MO: [
			{
				title: "Jackson",
				location: "University of Missouri Extension Center of Cape Girardeau County",
				address: "684 Jackson Trail, Jackson, MO 63755",
				coordinates: { lat: 37.36924469988073, lng: -89.66910388667311 },
				date: "12/05/2023",
				time: "9:30 AM – 12:00 PM CST",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-e078d621ad67443896aa60e50f1d7860"
			},
			{
				title: "Fulton",
				location: "Callaway Electric Cooperative",
				address: "1313 Cooperative Dr, Fulton, MO 65251",
				coordinates: { lat: 38.82409929152283, lng: -91.97707610821695 },
				date: "12/11/2023",
				time: "9:30 AM – 12:00 PM CST",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-fb01d72ebc644d9bba14c31028319e24"
			},
			{
				title: "Trenton",
				location: "North Central Missouri College Barton Farm Campus",
				address: "96 S E 8th Ave, Trenton, MO 64683",
				coordinates: { lat: 40.07810190259239, lng: -93.61849017420784 },
				date: "12/12/2023",
				time: "9:30 AM – 12:00 PM CST",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-d8501fbb386a4bd3b02045e740a1dbe6"
			},
			{
				title: "Lamar",
				location: "Thiebaud Auditorum",
				address: "105 E 11th St, Lamar, MO 64759",
				coordinates: { lat: 37.49419019530999, lng: -94.27525041847224 },
				date: "12/13/2023",
				time: "9:30 AM – 12:00 PM CST",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-c2b8acc8ce5d41c4b017d5f6c0722870"
			}
		],
		IL: [
			{
				title: "Marion",
				location: "Farm Credit Illinois",
				address: "2601 Henry Dr, Marion, IL 62959",
				coordinates: { lat: 37.73250467153018, lng: -88.96150553231814 },
				date: "12/06/2023",
				time: "9:30 AM – 12:00 PM CST",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-86cbd0a9e0c7425a85a0ed627d6921a0"
			},
			{
				title: "Gilman",
				location: "Ford-Iroquois Farm Bureau",
				address: "1381 S Crescent St, Gilman, IL 60938",
				coordinates: { lat: 40.753118846011795, lng: -87.99603346475405 },
				date: "02/06/2024",
				time: "9:30 AM – 12:00 PM CST",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-55a800f8cc4243238ae06022595064bf"
			},
			{
				title: "Sycamore",
				location: "Dekalb County Farm Bureau",
				address: "1350 W Prairie Dr, Sycamore, IL 60178",
				coordinates: { lat: 41.96675841169203, lng: -88.70462984587964 },
				date: "02/07/2024",
				time: "9:30 AM – 12:00 PM CST",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/s_reg/reg_registration_maintenance.aspx?ek=0081-0004-57c271a635cd42a3803d57979fa86709"
			},
			{
				title: "Quincy",
				location: "Adams County Farm Bureau ",
				address: "330 S 36th St # 2, Quincy, IL 62301",
				coordinates: { lat: 39.929019508490875, lng: -91.35892961713665 },
				date: "02/08/2024",
				time: "9:30 AM – 12:00 PM CST",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-3cfb210432f8462b8cd0b429b0e54b91"
			}
		],
		IA: [
			{
				title: "Storm Lake",
				location: "Buena Vista County Extension Services ",
				address: "701 Seneca St #1, Storm Lake, IA 50588",
				coordinates: { lat: 42.6457792, lng: -95.1962634 },
				date: "11/12/2024",
				time: "9:30 AM – 11:00 AM",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-0405eaed3714408293a116708df5ed8b"
			},
			{
				title: "Elkader",
				location: "Osborne Visitor Welcome Center 3F ",
				address: "29862 Osborne Rd, Elkader, IA 52043",
				coordinates: { lat: 42.7911721, lng: -91.4454421 },
				date: "11/13/2024",
				time: "9:30 AM – 11:00 AM",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-09c99ed4f2e74ac7a858273ec3c84923"
			},
			{
				title: "Winterset",
				location: "AgriVision Equipment ",
				address: "1200 IA-92, Winterset, IA 50273",
				coordinates: { lat: 41.3433251, lng: -94.0004631 },
				date: "11/14/2024",
				time: "9:30 AM – 11:00 AM",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-1f1f03cb6002410b9960a57bd1a30c8f"
			}
		],
		IN: [
			{
				title: "Bedford",
				location: "StoneGate Arts and Education Center ",
				address: "931 15th St, Bedford, IN 47421",
				coordinates: { lat: 38.8622620, lng: -86.4839257 },
				date: "12/10/2024",
				time: "9:30 AM – 11:00 AM",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-bb71d0cae1ee4262817b3b104eb1d6ec"
			},
			{
				title: "Logansport",
				location: "Cass County Fairgrounds ",
				address: "2281 E County Rd 125 N, Logansport, IN 46947",
				coordinates: { lat: 40.7843513, lng: -86.3322448 },
				date: "12/11/2024",
				time: "9:30 AM – 11:00 AM",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-9ab8196ee6c149d8b73d8728e3ea042f"
			}
		],
		OH: [
			{
				title: "Tiffin",
				location: "Seneca County Public Safety Building ",
				address: "2636, 126 Hopewell Ave, Tiffin, OH 44883",
				coordinates: { lat: 41.1079673, lng: -83.2014112 },
				date: "12/12/2024",
				time: "9:30 AM – 11:00 AM",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-9cec1a7e1b6243a9941595eed559eeda"
			},
			{
				title: "Urbana",
				location: "Champaign County Farm Service Agency ",
				address: "1512 US-68 N100, Urbana, OH 43078",
				coordinates: { lat: 40.0898817, lng: -83.7577003 },
				date: "12/13/2024",
				time: "9:30 AM – 11:00 AM",
				note: "This is an in-person workshop.",
				link: "https://reg.extension.msstate.edu/reg/event_page.aspx?ek=0081-0004-c2d6bb0749f04afe9ea321efa117a03e"
			}
		]
	};

	const virtualWorkshops = [
		{
			title: "Landowner Workshop 1",
			date: "03/05/2024",
			time: "9:45 AM – 12:00 PM CST",
			link: "https://msstate.webex.com/weblink/register/rf5f5f5313569e599cdfb2074b8838abd"
		},
		{
			title: "Landowner Workshop 2",
			date: "03/07/2024",
			time: "9:45 AM – 12:00 PM CST",
			link: "https://msstate.webex.com/weblink/register/ra04807f36e0299278fae27260220c391"
		},
		{
			title: "Landowner Workshop 3",
			date: "03/19/2024",
			time: "9:45 AM – 12:00 PM CST",
			link: "https://msstate.webex.com/weblink/register/r30f387da9ff403fa0178ff2cddeec2ad"
		}
	];

	const onImageClickIL = () => {
		setShowWorkshopsIL(!showWorkshopsIL);
	};

	const onImageClickMS = () => {
		setShowWorkshopsMS(!showWorkshopsMS);
	};

	const onImageClickMO = () => {
		setShowWorkshopsMO(!showWorkshopsMO);
	};
	
	const onImageClickVR = () => {
		setShowWorkshopsVR(!showWorkshopsVR);
	};

	const onSummaryClickIL = () => {
		setFileToOpen("Illinois.pdf");
		setIsModalOpen(true);
	};

	const onSummaryClickMS = () => {
		setFileToOpen("Mississippi.pdf");
		setIsModalOpen(true);
	};

	const onSummaryClickMO = () => {
		setFileToOpen("Missouri.pdf");
		setIsModalOpen(true);
	};
	
	const onSummaryClickVR = () => {
		setFileToOpen("Virtual.pdf");
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Header />
			<Row>
				<Col span={20} offset={2}>
					<h2 className="mt-3 workshop-header">Upcoming Events</h2>
				</Col>

				<Col span={20} offset={2}>
					<h3>
						We are excited to expand into five new states: Iowa, Indiana, Ohio, Arkansas, and Louisiana.
						We are planning workshops across these states in late 2024 through early 2025 and encourage
						landowners and agency staff to attend. More information is to come on dates and locations.
						In the meantime, please visit our booth at the events below to know more about CRP Menu Tool
						and subscribe to our newsletter
						<Link to="/Contact" style={{ color: "maroon" }}> here </Link>
						to receive updates.
					</h3>
				</Col>

				<Col span={20} offset={2} className="workshop-container">
					<Row>
						<MdAgriculture size="120px" style={{margin: "20px 40px"}} />
						<Col xs={{span: 20}} md={{span: 15}} xl={{span: 7}} className="workshop-card">
							<h3 className="workshop-header">
								<a className="event-header" href="https://www.midwestagexpo.com/" target="_blank">
									<u>Midwest Ag Expo</u>
								</a>
							</h3>
							<div><b>Date: </b>Wednesday, January 22, 2025 - Thursday, January 23, 2025</div>
							<div><b>Time: </b>8:30 am - 4:00 pm</div>
							<div><b>Location: </b>Gordyville USA Arena</div>
							<div><b>Address: </b>2205 County Rd 3000 N, Gifford, IL 61847</div>
						</Col>
						<MdAgriculture size="120px" style={{margin: "20px 40px"}} />
						<Col xs={{span: 20}} md={{span: 15}} xl={{span: 7}} className="workshop-card">
							<h3 className="workshop-header">
								<a className="event-header" href="https://www.farmandginshow.com/" target="_blank">
									<u>Mid-South Farm & Gin Show</u>
								</a>
							</h3>
							<div><b>Date: </b>Friday, February 28, 2025 - Saturday, March 1, 2025</div>
							<div><b>Time: </b>9:00 am - 5:00 pm</div>
							<div><b>Location: </b>Renasant Convention Center</div>
							<div><b>Address: </b>255 N Main St, Memphis, TN 38103</div>
						</Col>
					</Row>
				</Col>

				<Col span={20} offset={2}>
					<h2 className="mt-3 workshop-header">Previous Workshops</h2>
				</Col>
				
				<Col span={20} offset={2}>
					<h3>
						We conducted 12 in-person workshops and 3 virtual workshops with local farmers and agencies
						in three pilot states: Illinois, Missouri, Mississippi, during late 2023 and early 2024.
						To view the workshop locations and summary, please click the icon of each state below.
					</h3>
				</Col>
				
				<Col span={20} offset={2}>
					<Row>
						<Col xs={{span: 20, offset: 1}} md={{span: 10, offset: 1}} xl={{span: 5, offset: 0}}>
							<ReactCardFlip className="workshop-container" isFlipped={showWorkshopsIL} flipDirection="horizontal">
								<Tooltip title="Previous workshops in Illinois">
									<img
										className="workshop-image"
										src={illinois}
										alt="illinois"
										onClick={onImageClickIL}
									></img>
									<b className="workshop-label">Illinois</b>
								</Tooltip>
								<div className="workshop-archive" onClick={onImageClickIL}>
									{workshops.IL.map((workshop) => (
										<p className="workshop-archive-labels">
											<b>{workshop.title}</b>{" " + workshop.date}
										</p>
									))}
									<Tooltip title="Illinois Workshop Summary">
										<Button className="workshop-summary-button" onClick={onSummaryClickIL}>
											<HiDocumentReport style={{marginBottom: "-2px"}} />
											Illinois Workshop Summary
										</Button>
									</Tooltip>
								</div>
							</ReactCardFlip>
						</Col>

						<Col xs={{span: 20, offset: 1}} md={{span: 10, offset: 2}} xl={{span: 5, offset: 1}}>
							<ReactCardFlip className="workshop-container" isFlipped={showWorkshopsMS} flipDirection="horizontal">
								<Tooltip title="Previous workshops in Mississippi">
									<img
										className="workshop-image"
										src={mississippi}
										alt="mississippi"
										onClick={onImageClickMS}
									></img>
									<b className="workshop-label">Mississippi</b>
								</Tooltip>
								<div className="workshop-archive" onClick={onImageClickMS}>
									{workshops.MS.map((workshop) => (
										<p className="workshop-archive-labels">
											<b>{workshop.title}</b>{" " + workshop.date}
										</p>
									))}
									<Tooltip title="Mississippi Workshop Summary">
										<Button className="workshop-summary-button" onClick={onSummaryClickMS}>
											<HiDocumentReport style={{marginBottom: "-2px"}} />
											Mississippi Workshop Summary
										</Button>
									</Tooltip>
								</div>
							</ReactCardFlip>
						</Col>
						
						<Col xs={{span: 20, offset: 1}} md={{span: 10, offset: 1}} xl={{span: 5, offset: 1}}>
							<ReactCardFlip className="workshop-container" isFlipped={showWorkshopsMO} flipDirection="horizontal">
								<Tooltip title="Previous workshops in Missouri">
									<img
										className="workshop-image"
										src={missouri}
										alt="missouri"
										onClick={onImageClickMO}
									></img>
									<b className="workshop-label">Missouri</b>
								</Tooltip>
								<div className="workshop-archive" onClick={onImageClickMO}>
									{workshops.MO.map((workshop) => (
										<p className="workshop-archive-labels">
											<b>{workshop.title}</b>{" " + workshop.date}
										</p>
									))}
									<Tooltip title="Missouri Workshop Summary">
										<Button className="workshop-summary-button" onClick={onSummaryClickMO}>
											<HiDocumentReport style={{marginBottom: "-2px"}} />
											Missouri Workshop Summary
										</Button>
									</Tooltip>
								</div>
							</ReactCardFlip>
						</Col>

						<Col xs={{span: 20, offset: 1}} md={{span: 10, offset: 2}} xl={{span: 5, offset: 1}}>
							<ReactCardFlip className="workshop-container" isFlipped={showWorkshopsVR} flipDirection="horizontal">
								<Tooltip title="Previous Virtual workshops">
									<img
										className="workshop-image"
										src={virtualWorkshop}
										alt="virtual"
										onClick={onImageClickVR}
									></img>
									<b className="workshop-label">Virtual</b>
								</Tooltip>
								<div className="workshop-archive" onClick={onImageClickVR}>
									{virtualWorkshops.map((workshop) => (
										<p className="workshop-archive-labels">
											<b>{workshop.title}</b>{" " + workshop.date}
										</p>
									))}
									<Tooltip title="Virtual Workshop Summary">
										<Button className="workshop-summary-button" onClick={onSummaryClickVR}>
											<HiDocumentReport style={{marginBottom: "-2px"}} />
											Virtual Workshop Summary
										</Button>
									</Tooltip>
								</div>
							</ReactCardFlip>
						</Col>
					</Row>
				</Col>

				<Col span={20} offset={2}>
					<div className="workshop-photo-container">
						<img className="workshop-photo" src={workshopPhoto1} alt="Workshop Photo"></img>
						<label className="workshop-photo-label">Workshop in Pontotoc, MS</label>
					</div>
				</Col>
			</Row>

			<Modal centered title="" open={isModalOpen} onCancel={handleCancel} width="80%" footer={null}>
				<iframe
					className="embed-responsive-item_basics"
					src={'/Workshops/' + fileToOpen}
					allowFullScreen
				/>
			</Modal>

			<Footer />
		</>
	);
}
export default Workshops;
