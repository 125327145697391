import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Map, { Source, Layer, Marker } from "react-map-gl";
import {
  Button,
  Collapse,
  Col,
  Row,
  Table,
  Modal,
  Form,
  Input,
  Switch,
  List,
  Tooltip,
  Spin,
  notification,
} from "antd";
import {
  stateFilter,
  stateCode,
  safeLabels,
  landCoverTypeLabels,
  priorityLabels,
  columns,
  safePractices,
  withinFloodplainPractices,
  outsideFloodplainPractices,
  waterBufferPractices,
} from "./utils/helperFunctions";
import {
  TbHexagonLetterC,
  TbHexagonLetterP,
  TbHexagonLetterR,
  TbHexagonLetterS,
  TbSquareLetterC,
  TbSquareLetterG,
} from "react-icons/tb";
import * as htmlToImage from "html-to-image";
import { LuLogIn, LuMap, LuPrinter, LuSave } from "react-icons/lu";
import polylabel from "polylabel";
import { geoArea } from "d3-geo";
import bbox from "@turf/bbox";
import center from "@turf/center";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { ModuleContext } from "../../context/Context";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import hostURL from "../../Common/constants";
import PracticeCard from "../PracticeList/Components/PracticeCard";
import "./style.css";
import PrintHelp from "./Components/PrintHelp";
import { PrinterOutlined } from "@ant-design/icons";
import { MdOutlineCancel } from "react-icons/md";

const FieldPrint = ({ userFavoritePractices }) => {
  const [items, setItems] = useState([]);
  const [printList, setPrintList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [soilData, setSoilData] = useState(null);
  const [safeData, setSafeData] = useState(null);
  const [floodplainData, setFloodplainData] = useState(null);
  const [waterData, setWaterData] = useState(null);
  const [majorSoilTypes, setMajorSoilTypes] = useState([]);
  const [fieldBasics, setFieldBasics] = useState(null);
  const [fieldFeatureData, setFieldFeatureData] = useState(null);
  const [fieldAddress, setFieldAddress] = useState("Not Specified");
  const [fieldSize, setFieldSize] = useState(null);
  const [fieldRentalRate, setFieldRentalRate] = useState(null);
  const [soilTableData, setSoilTableData] = useState(null);
  const [baseLayer, setBaseLayer] = useState(
    "mapbox://styles/mapbox/satellite-streets-v12"
  );
  const [layerSelectOpen, setLayerSelectOpen] = useState(false);
  const [soilLayerOn, setSoilLayerOn] = useState(true);
  const [safeLayerOn, setSafeLayerOn] = useState(false);
  const [floodplainLayerOn, setFloodplainLayerOn] = useState(false);
  const [waterLayerOn, setWaterLayerOn] = useState(false);
  const [interactiveLayerIds, setInteractiveLayerIds] = useState([]);
  const [filter, setFilter] = useState([]);
  const [highlightFeatureId, setHighlightFeatureId] = useState(null);
  const [selectedFips, setSelectedFips] = useState(null);
  const [selectedPracticeList, setselectedPracticeList] = useState(null);
  const [countyListData, setCountyListData] = useState(null);
  const [selectedTopPracticeList, setselectedTopPracticeList] = useState(null);
  const [filteredPractices, setFilteredPractices] = useState(null);
  const [eligiblePractices, setEligiblePractices] = useState(null);
  const [eligiblePracticesData, setEligiblePracticesData] = useState([]);
  const [eligiblePracticesPriorities, setEligiblePracticesPriorities] =
    useState([]);
  const [isSavePopOpen, setIsSavePopOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [preferredPractice, setPreferredPractice] = useState(null);
  const [preferredSignups, setPreferredSignups] = useState({
    G: true,
    C: true,
  });
  const [preferredIncentives, setPreferredIncentives] = useState({
    S: false,
    R: false,
    P: false,
    C: "5",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const mapRef = useRef();

  const printPracticeArray = [];

  const addPrintPracticeArray = (practice) => {
    if (!!practice && !printPracticeArray.includes(practice)) {
      printPracticeArray.push(practice);
    }
  };

  const removePrintPracticeArray = (practice) => {
    if (!!practice && printPracticeArray.includes(practice)) {
      const index = printPracticeArray.indexOf(practice);
      if (index > -1) {
        printPracticeArray.splice(index, 1);
      }
    }
  };

  const [form] = Form.useForm();
  const authToken = secureLocalStorage.getItem("token");
  const fieldDetails = secureLocalStorage.getItem("field-details");
  const priorityPreference = secureLocalStorage.getItem("priority-preference");
  const rankingScore = secureLocalStorage.getItem("ranking-score");

  const showPopconfirm = () => {
    setIsSavePopOpen(true);
  };

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  const saveSearchPopClose = () => {
    form.resetFields();
    setIsSavePopOpen(false);
  };

  const storeSearchData = async (values, sroute) => {
    form.resetFields();
    setIsSavePopOpen(false);
    let imageDataURL = null;
    setLoading(true);

    await htmlToImage
      .toPng(document.getElementById("fieldReportMap"), { allowTaint: true })
      .then(function (dataurl) {
        imageDataURL = dataurl;
      });

    const searchedData = {
      selectedState: fieldBasics.state,
      selectedCounty: fieldBasics.county,
      step2CheckedTypes: priorityPreference.landCoverTypes,
      step3CheckedTypes: priorityPreference.priorities,
      rankingScore: rankingScore,
      mapimage: imageDataURL,
      data: fieldDetails,
    };

    const formData = {
      userId: secureLocalStorage.getItem("userRef"),
      route: window.location.href,
      formData: JSON.stringify(searchedData),
      mySearchName: values.SaveKey,
      stype: "checkEligibility",
    };

    setLoading(true);
    fetch(hostURL + "api/usersearchdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((json) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Saved Your Search Criteria"
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        openNotificationWithIcon("error", "Error", "Try Again Later");
      });
  };

  const getFieldAddress = async () => {
    const fieldGeometry = JSON.parse(fieldDetails.countyRate.rows[0].geometry);
    const centerPoint = center(fieldGeometry);
    const [lng, lat] = centerPoint.geometry.coordinates;
    setLoading(true);
    const response = await axios.get(
      "https://api.mapbox.com/search/geocode/v6/reverse?longitude=" +
        lng +
        "&latitude=" +
        lat +
        "&access_token=pk.eyJ1IjoiY2h1Y2swNTIwIiwiYSI6ImNrMDk2NDFhNTA0bW0zbHVuZTk3dHQ1cGUifQ.dkjP73KdE6JMTiLcUoHvUA"
    );
    if (response.data) {
      setTimeout(() => {
        setLoading(false);
      }, 6000);
      setFieldAddress(response.data.features[0].properties.full_address);
    }
  };

  const getFieldRentalRate = () => {
    const fieldRate = fieldDetails.fieldRate;
    const fieldSpecifics = fieldRate.rows;
    const soilTypes = [...new Set(fieldSpecifics.map((field) => field.musym))];

    const SoilTypeData = soilTypes.map((soilType) => {
      const fieldsBySoilType = fieldSpecifics.filter(
        (field) => field.musym === soilType
      );
      const srr = fieldsBySoilType[0].srr;
      const areas = fieldsBySoilType.map((field) => field.area);
      const getTotalArea = (total, area) => {
        return total + area;
      };
      const totalArea = areas.reduce(getTotalArea, 0);
      return { type: soilType, srr: srr, totalArea: totalArea };
    });

    const getTopThree = (arr) => {
      arr.sort((a, b) => (a < b ? 1 : a > b ? -1 : 0));
      return arr.slice(0, 3);
    };

    const totalAreas = SoilTypeData.map((soilType) => soilType.totalArea);
    const topThreeTotalAreas = getTopThree(totalAreas);
    const topThreeSoilTypes = SoilTypeData.filter((soilType) =>
      topThreeTotalAreas.includes(soilType.totalArea)
    );
    setMajorSoilTypes(topThreeSoilTypes);
  };

  const getPracticeList = (queryState, queryCounty) => {
    setLoading(true);
    fetch(
      `${hostURL}api/statepractices/${queryState}/${queryCounty}`
    )
      .then((response) => response.json())
      .then((json) => {
        const respData = json[0];
        setselectedPracticeList(json);
        setSelectedFips(respData.fips);
        let toShowData =
          respData &&
          JSON.parse(
            respData.assetdata.substring(1, respData.assetdata.length - 1)
          );
        setFilteredPractices(toShowData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleLayerSelect = () => {
    setLayerSelectOpen(!layerSelectOpen);
  };

  const getMarkerLocation = (geometry) => {
    let output = [];
    if (geometry.type === "Polygon") {
      output = polylabel(geometry.coordinates, 0.000001);
    } else {
      let maxArea = 0,
        maxPolygon = [];
      for (let i = 0, l = geometry.coordinates.length; i < l; i++) {
        const p = geometry.coordinates[i];
        const area = geoArea({ type: "Polygon", coordinates: p });
        if (area > maxArea) {
          maxPolygon = p;
          maxArea = area;
        }
      }
      output = polylabel(maxPolygon, 0.000001);
    }
    return output;
  };

  const onMouseMove = (e) => {
    if (e.features.length) {
      const feature = e.features[0];
      setFilter(["in", "gid", feature.id]);
      setHighlightFeatureId(feature.id);
    }
  };

  useEffect(() => {
    if (fieldBasics) {
      setLoading(true);
      fetch(hostURL + "api/counties?state=" + stateCode[fieldBasics.state])
        .then((response) => response.json())
        .then((json) => {
          setCountyListData(json);
          setLoading(false);
        })
        .catch((error) => console.error(error));
    }
  }, [fieldBasics]);

  useEffect(() => {
    if (countyListData && fieldBasics) {
      let countyDataArray = countyListData.filter(
        (item) => item.cname === fieldBasics.county
      );
      let assetData =
        countyDataArray.length > 0 ? countyDataArray[0].assetdata : null;
      let topPracticeList = assetData
        ? assetData.replace('"', "").split(",")
        : null;
      setselectedTopPracticeList(topPracticeList);
    }
  }, [countyListData, fieldBasics]);

  useEffect(() => {
    if (
      eligiblePractices &&
      eligiblePracticesPriorities?.length > 0 &&
      rankingScore.length > 0
    ) {
      let eligiblePracticesScores = eligiblePracticesPriorities.map((data) => {
        let totalScoreArray = rankingScore.map((item) => {
          let score = data[item.priority] ? item.score : 0;
          return score;
        });
        let totalScore = totalScoreArray.reduce((a, b) => {
          return a + b;
        }, 0);
        return {
          pnumber: data.pnumber,
          score: totalScore,
        };
      });

      let scoredEligiblePractices = eligiblePractices.map((practice) => {
        let scoreArray = eligiblePracticesScores.filter(
          (item) => item.pnumber === practice.title.split("-")[0].trim()
        );
        let score = scoreArray.length > 0 ? scoreArray[0].score : 0;
        return {
          title: practice.title,
          pdflink: practice.pdflink,
          notes: practice.notes,
          score: score,
        };
      });

      let sortedEligiblePractices = scoredEligiblePractices.sort(
        (a, b) => b.score - a.score
      );
      setEligiblePractices(sortedEligiblePractices);
    }
  }, [eligiblePracticesPriorities, rankingScore]);

  useEffect(() => {
    if (fieldDetails) {
      getFieldRentalRate();
      setFieldBasics(fieldDetails.countyRate.rows[0]);
      const soilTableData = fieldDetails.fieldRate.rows.map((soil, index) => {
        return {
          key: soil.gid,
          soil_type: soil.musym,
          rental_rate: "$" + soil.srr + "/acre",
          acreage: Math.round(soil.area * 0.000247105 * 100) / 100,
        };
      });
      setSoilTableData(soilTableData.sort((a, b) => b.acreage - a.acreage));

      const soilFeatures = fieldDetails.fieldRate.rows.map((soil) => {
        const soilGeometry = JSON.parse(soil.geometry);
        return {
          type: "Feature",
          geometry: soilGeometry,
          id: soil.gid,
          properties: { gid: soil.gid, musym: soil.musym },
        };
      });
      const soilFeatureCollection = {
        type: "FeatureCollection",
        features: soilFeatures,
      };
      setSoilData(soilFeatureCollection);
      setInteractiveLayerIds(["soil-layer"]);

      if (fieldDetails.safeArea.rows.length > 0) {
        const safeFeatures = fieldDetails.safeArea.rows.map((safe) => {
          const safeGeometry = JSON.parse(safe.geometry);
          return { type: "Feature", geometry: safeGeometry };
        });
        const safeFeatureCollection = {
          type: "FeatureCollection",
          features: safeFeatures,
        };
        setSafeData(safeFeatureCollection);
      }

      if (fieldDetails.floodPlain.rows.length > 0) {
        const floodplainFeatures = fieldDetails.floodPlain.rows.map(
          (floodplain) => {
            const floodplainGeometry = JSON.parse(floodplain.geometry);
            return { type: "Feature", geometry: floodplainGeometry };
          }
        );
        const floodplainFeatureCollection = {
          type: "FeatureCollection",
          features: floodplainFeatures,
        };
        setFloodplainData(floodplainFeatureCollection);
      }

      if (fieldDetails.waterBuffer.rows.length > 0) {
        const waterBufferFeatures = fieldDetails.waterBuffer.rows.map(
          (waterBuffer) => {
            const waterBufferGeometry = JSON.parse(waterBuffer.geometry);
            return { type: "Feature", geometry: waterBufferGeometry };
          }
        );
        const waterBufferFeatureCollection = {
          type: "FeatureCollection",
          features: waterBufferFeatures,
        };
        setWaterData(waterBufferFeatureCollection);
      }
    }
  }, [fieldDetails]);

  useEffect(() => {
    const rateBySoilTypes = majorSoilTypes.map((soilType) => {
      const areaInAcre = soilType.totalArea * 0.0002471;
      const rateBySoilType = soilType.srr * areaInAcre;
      return rateBySoilType;
    });
    const getTotalRate = (total, rate) => {
      return total + rate;
    };
    const totalRentalRate = Math.round(rateBySoilTypes.reduce(getTotalRate, 0));
    setFieldRentalRate(totalRentalRate);
  }, [majorSoilTypes]);

  useEffect(() => {
    if (mapRef.current) {
      const [minLng, minLat, maxLng, maxLat] = bbox(fieldFeatureData);
      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 40, duration: 1000 }
      );
    }
  }, [fieldFeatureData]);

  useEffect(() => {
    if (fieldBasics) {
      getFieldAddress();
      setFieldSize(fieldBasics.area * 0.000247105);
      setFieldFeatureData({
        type: "Feature",
        geometry: JSON.parse(fieldBasics.geometry),
      });
      getPracticeList(stateCode[fieldBasics.state], fieldBasics.county);
    }
  }, [fieldBasics]);

  useEffect(() => {
    if (filteredPractices) {
      const eligiblePracticeList = filteredPractices.filter((practice) => {
        const prTitle = practice.title;
        const prCode = prTitle.split("-")[0].trim();
        if (Object.values(safePractices).includes(prTitle)) {
          const safeAreaList = fieldDetails.safeArea.rows;
          const safeNameList = safeAreaList.map(
            (safe) => safePractices[safe.code]
          );
          if (safeNameList.includes(prTitle)) {
            return true;
          } else {
            return false;
          }
        } else if (withinFloodplainPractices.includes(prCode)) {
          return floodplainData ? true : false;
        } else if (outsideFloodplainPractices.includes(prCode)) {
          return floodplainData ? false : true;
        } else if (
          Object.keys(waterBufferPractices).includes(prCode) &&
          fieldDetails
        ) {
          const waterBufferList = fieldDetails.waterBuffer.rows;
          const bufferDistanceList = new Set(
            waterBufferList.map((buffer) => buffer.distance)
          );
          const minDistance = waterBufferPractices[prCode].min;
          const maxDistance = waterBufferPractices[prCode].max;
          if (minDistance && maxDistance) {
            if (
              !bufferDistanceList.has(minDistance) &&
              bufferDistanceList.has(maxDistance)
            ) {
              return true;
            } else {
              return false;
            }
          } else if (minDistance) {
            if (!bufferDistanceList.has(minDistance)) {
              return true;
            } else {
              return false;
            }
          } else if (maxDistance) {
            if (bufferDistanceList.has(maxDistance)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      });
      setEligiblePractices(eligiblePracticeList);
      (async () => {
        // setLoading(true);
        let metaData = [];
        let priorityData = [];
        await Promise.all(
          eligiblePracticeList.map(async (practice) => {
            const prTitle = practice.title;
            const prCode = prTitle.split("-")[0].trim();
            const prName = prTitle.split(/-(.*)/)[1].trim();
            await fetch(
              `${hostURL}api/natpractices/${prCode}/${encodeURIComponent(
                prName
              )}`
            )
              .then((response) => {
                const contentType = response.headers.get("content-type");
                if (
                  contentType &&
                  contentType.indexOf("application/json") !== -1
                ) {
                  return response.json();
                }
              })
              .then((json) => {
                if (json) {
                  metaData.push(json);
                }
              })
              .catch((error) => console.error(error));
          })
        );
        await Promise.all(
          eligiblePracticeList.map(async (practice) => {
            const prTitle = practice.title;
            const prCode = prTitle.split("-")[0].trim();
            const prName = prTitle.split(/-(.*)/)[1].trim();
            await fetch(
              `${hostURL}api/crppriorities/${prCode}/${encodeURIComponent(
                prName
              )}`
            )
              .then((response) => {
                const contentType = response.headers.get("content-type");
                if (
                  contentType &&
                  contentType.indexOf("application/json") !== -1
                ) {
                  return response.json();
                }
              })
              .then((json) => {
                if (json) {
                  priorityData.push(json);
                }
              })
              .catch((error) => console.error(error));
          })
        );
        setEligiblePracticesData(metaData);
        setEligiblePracticesPriorities(priorityData);
        // setLoading(false);
      })();
    }
  }, [filteredPractices]);
  // For unit conversion, 1 square meter = 0.000247105 acres

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   if (printList) window.print();
  // }, [fieldFeatureData, printList]);

  // const printing = () => {
  //   window.print();
  //   setPrintList(false);
  // };

  // const handlePrint = () => {
  //   setPrintList(true);
  // };

  function titleCase(str) {
    return str
      ?.toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ");
  }

  const date = new Date();
  let day = ("0" + date.getDate()).slice(-2);
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear() - 2000;
  let currentDate = `${month}/${day}/${year}`;

  const getLabels = {
    winderosion: "Wind Erosion",
    watererosion: "Water Erosion",
    soilhealth: "Soil Health",
    excesswind: "Excess Wind",
    drought: "Drought",
    elevatedwatertemperature: "Water Temperature",
    flooding: "Flooding",
    snowdrift: "Snow Drift",
    waterquality: "Water Quality",
    aquatic: "Aquatic",
    biggame: "Big Game",
    grasslandbirds: "Grassland Birds",
    forestbirds: "Forest Birds",
    pollinators: "Pollinators",
    waterfowl: "Waterfowl",
    carbonstorage: "Carbon Storage",
    aquaculture: "Aquaculture Ponds",
    cropland: "Cropland",
    timber: "Timber",
    pasture: "Pasture",
    wetland: "Wetland",
  };

  if (!fieldDetails) {
    navigate("/");
    return;
  }

  return (
    <>
      <Spin spinning={loading}>
        <Header noNavigation={"print"} />
        <div className="print report">
          <div className="report-head-container">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2
                className={printList ? "print report-header" : "report-header"}
              >
                CRP Field Eligibility Report:
                <span
                  style={{ fontWeight: "normal" }}
                >{` ${fieldBasics?.county}, ${fieldBasics?.state}`}</span>
              </h2>
              <div className="basic-info-container">
                <div className="report-basic-information">
                  <p>
                    <b>FIPS Code: </b>
                    {fieldBasics?.fips} | <b>Field Size: ≈ </b>
                    {Math.floor(fieldSize)} acre |{" "}
                    {/* <b>County Average Rental Rate*: </b>$
                    {Math.round(fieldBasics?.srr2024 * 100) / 100}/acre */}
                  </p>
                  <p>
                    <b>SAFE Zone(s): </b>
                    {fieldDetails.safeArea.rows.length === 0
                      ? "No"
                      : fieldDetails.safeArea.rows
                          .map((item) => safeLabels[item.code])
                          .join(", ")}{" "}
                    | <b>Floodplain: </b>
                    {fieldDetails.floodPlain.rows.length ? "Yes" : "No"} |{" "}
                    <b>Selected Priorities: </b>
                    {priorityPreference.priorities.length
                      ? priorityPreference.priorities.map((priority, index) => {
                          if (index === 0) {
                            return priorityLabels[priority];
                          } else {
                            return ", " + priorityLabels[priority];
                          }
                        })
                      : "No priority selected, please go back to explore CRP practices"}
                  </p>
                  <p>
                    <b>Top 3 soil types: </b>
                    {majorSoilTypes &&
                      majorSoilTypes.map((soilType, index) => (
                        <span key={soilType.type}>
                          {`${soilType.type} (${
                            Math.round(soilType.totalArea * 0.0002471 * 100) /
                            100
                          } acres @ $${soilType.srr}/acre)`}
                          {index < majorSoilTypes.length - 1 && ", "}
                        </span>
                      ))}
                    . To understand these soil type codes, please find the
                    details{" "}
                    <a
                      href="https://www.nrcs.usda.gov/publications/Map%20Unit%20Description%20%28Brief%2C%20Generated%29%20Query%20by%20Soil%20Survey%20Area%20and%20Map%20Unit%20%28All%20Components%29.html"
                      target="_blank"
                      style={{ color: "maroon" }}
                      rel="noreferrer"
                    >
                      here
                    </a>
                    .
                  </p>
                  <p>
                    <b>Total weighted average rental rate estimate: </b>
                    <b> ${fieldRentalRate}</b>
                    <sup>[1]</sup>
                  </p>
                  <p>
                    <b>Total weighted average rental rate per acre estimate: </b>
                    <b>
                      ${Math.round(fieldRentalRate / fieldSize)}
                      /acre
                    </b>
                    *
                  </p>
                </div>
              </div>
            </div>
            <div className="report-head-btn-container">
              <Button
                onClick={() => window.print()}
                className="print-button filter-button"
              >
                <b>
                  <PrinterOutlined /> Print
                </b>
              </Button>

              <Button onClick={() => window.close()} className="close-button ">
                <b className="close-text">
                  <MdOutlineCancel /> <span>Close</span>
                </b>
              </Button>
            </div>
          </div>

          <hr className="report-hr" />
          <div className="report-map-container">
            <h3 className={"report-section-header"}>Field Boundary:</h3>
            <div className="print report-map-inner-container">
              <Map
                id="fieldReportMap"
                ref={mapRef}
                mapboxAccessToken="pk.eyJ1IjoiY2h1Y2swNTIwIiwiYSI6ImNrMDk2NDFhNTA0bW0zbHVuZTk3dHQ1cGUifQ.dkjP73KdE6JMTiLcUoHvUA"
                initialViewState={{
                  longitude: -91,
                  latitude: 36,
                  preserveDrawingBuffer: true,
                  zoom: 4,
                }}
                mapStyle={baseLayer}
                interactiveLayerIds={interactiveLayerIds}
                onMouseMove={onMouseMove}
              >
                <Tooltip placement="bottom" title="Layer Tool">
                  <Button
                    className="report-layer-select"
                    onClick={handleLayerSelect}
                  >
                    <LuMap strokeWidth={3} />
                  </Button>
                </Tooltip>
                {layerSelectOpen && (
                  <div className="report-layer-options">
                    <div className="base-layers">
                      <p>
                        <b>Base Layers</b>
                      </p>
                      <p>
                        <img
                          alt="thumbnail for light view of map"
                          className="layer-thumbnail"
                          src="https://api.mapbox.com/styles/v1/mapbox/light-v11/static/-88.81,33.45,7.0,0,0/50x50?access_token=pk.eyJ1IjoiY2h1Y2swNTIwIiwiYSI6ImNrMDk2NDFhNTA0bW0zbHVuZTk3dHQ1cGUifQ.dkjP73KdE6JMTiLcUoHvUA"
                        />
                        <Switch
                          className="report-layer-switch"
                          checked={
                            baseLayer ===
                            "mapbox://styles/mapbox/satellite-streets-v12"
                          }
                          checkedChildren="Satellite"
                          unCheckedChildren="light"
                          defaultChecked
                          onChange={(checked) => {
                            if (checked) {
                              setBaseLayer(
                                "mapbox://styles/mapbox/satellite-streets-v12"
                              );
                            } else {
                              setBaseLayer("mapbox://styles/mapbox/light-v11");
                            }
                          }}
                        />
                        <img
                          alt="thumbnail for light view of map"
                          className="layer-thumbnail"
                          src="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static/-88.81,33.45,7.0,0,0/50x50?access_token=pk.eyJ1IjoiY2h1Y2swNTIwIiwiYSI6ImNrMDk2NDFhNTA0bW0zbHVuZTk3dHQ1cGUifQ.dkjP73KdE6JMTiLcUoHvUA"
                        />
                      </p>
                    </div>
                    <hr />
                    <div className="supporting-layers">
                      <p>
                        <b>Supporting Layers</b>
                      </p>
                      <p>
                        <input
                          type="checkbox"
                          checked={soilLayerOn}
                          onClick={(e) => {
                            if (e.currentTarget.checked) {
                              setSoilLayerOn(true);
                            } else {
                              setSoilLayerOn(false);
                            }
                          }}
                        />
                        Soil Types
                        <div className="report-legend-soil"></div>
                      </p>
                      <p>
                        <input
                          type="checkbox"
                          checked={safeLayerOn}
                          onClick={(e) => {
                            if (e.currentTarget.checked) {
                              setSafeLayerOn(true);
                            } else {
                              setSafeLayerOn(false);
                            }
                          }}
                        />
                        SAFE Boundaries
                        <div className="report-legend-safe"></div>
                      </p>
                      <p>
                        <input
                          type="checkbox"
                          checked={floodplainLayerOn}
                          onClick={(e) => {
                            if (e.currentTarget.checked) {
                              setFloodplainLayerOn(true);
                            } else {
                              setFloodplainLayerOn(false);
                            }
                          }}
                        />
                        Floodplains
                        <div className="report-legend-floodplain"></div>
                      </p>
                      <p>
                        <input
                          type="checkbox"
                          checked={waterLayerOn}
                          onClick={(e) => {
                            if (e.currentTarget.checked) {
                              setWaterLayerOn(true);
                            } else {
                              setWaterLayerOn(false);
                            }
                          }}
                        />
                        Water Features
                        <div className="report-legend-water"></div>
                      </p>
                    </div>
                  </div>
                )}
                <Source
                  id="map-source"
                  type="vector"
                  url="mapbox://chuck0520.5pq9omti"
                  maxzoom={22}
                  minzoom={0}
                >
                  <Layer
                    id="county-boundary"
                    source-layer="c_08mr23-1pp4eg"
                    type="line"
                    paint={{
                      "line-color": "#800000",
                      "line-width": 1,
                      "line-opacity": 0.4,
                    }}
                    minzoom={0}
                    maxzoom={22}
                    filter={stateFilter}
                  />
                </Source>
                <Source
                  id="field-source"
                  type="geojson"
                  data={fieldFeatureData}
                >
                  <Layer
                    id="field"
                    type="line"
                    paint={{
                      "line-color": "white",
                      "line-width": 2,
                    }}
                  />
                </Source>
                {soilLayerOn && (
                  <Source id={"soil-source"} type="geojson" data={soilData}>
                    <Layer
                      id={"soil-layer"}
                      type="fill"
                      paint={{
                        "fill-outline-color": "white",
                        "fill-color": "transparent",
                      }}
                    />
                    <Layer
                      id={"soil-layer-highlight"}
                      type="fill"
                      paint={{
                        "fill-color": "purple",
                        "fill-opacity": 0.5,
                      }}
                      filter={filter}
                    />
                  </Source>
                )}
                {safeLayerOn && safeData && (
                  <Source id="safe-source" type="geojson" data={safeData}>
                    <Layer
                      id="safe"
                      type="fill"
                      paint={{
                        "fill-color": "orange",
                        "fill-opacity": 0.5,
                      }}
                    />
                  </Source>
                )}
                {floodplainLayerOn && floodplainData && (
                  <Source
                    id="floodplain-source"
                    type="geojson"
                    data={floodplainData}
                  >
                    <Layer
                      id="floodplain"
                      type="fill"
                      paint={{
                        "fill-color": "blue",
                        "fill-opacity": 0.5,
                      }}
                    />
                  </Source>
                )}
                {waterLayerOn && waterData && (
                  <Source id="water-source" type="geojson" data={waterData}>
                    <Layer
                      id="water"
                      type="fill"
                      paint={{
                        "fill-color": "cyan",
                        "fill-opacity": 0.5,
                      }}
                    />
                  </Source>
                )}
                {fieldDetails.fieldRate.rows.map((soil) => {
                  const soilGeometry = JSON.parse(soil.geometry);
                  const [lng, lat] = getMarkerLocation(soilGeometry);
                  return (
                    <>
                      {soilLayerOn && (
                        <Marker longitude={lng} latitude={lat} anchor="center">
                          <label style={{ color: "white" }}>{soil.musym}</label>
                        </Marker>
                      )}
                    </>
                  );
                })}
              </Map>
            </div>
          </div>
          <div className="report-rental-table-container">
            <h3
              className={
                printList
                  ? "report-section-header print"
                  : "report-section-header"
              }
            >
              Base Rental Rate and Breakdown Table<sup>[1]</sup>:
            </h3>

            <p className="report-table-information">
              This is a breakdown table of the soil types and corresponding
              rental rate regarding your field. Please find more about soil
              types in your county from{" "}
              <a
                href="https://www.nrcs.usda.gov/publications/Map%20Unit%20Description%20%28Brief%2C%20Generated%29%20Query%20by%20Soil%20Survey%20Area%20and%20Map%20Unit%20%28All%20Components%29.html"
                target="_blank"
                style={{ color: "maroon" }}
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
            <Table
              className={printList ? "print report-table" : "report-table"}
              columns={columns}
              dataSource={soilTableData?.slice(0, 5)}
              pagination={false}
              rowClassName={(record) =>
                record.key === highlightFeatureId
                  ? "report-table-highlight"
                  : " "
              }
            />
          </div>
          <div className="report-practice-container">
            <h3
              className={
                printList
                  ? "print report-section-header"
                  : "report-section-header"
              }
            >
              Eligible CRP Practices:
            </h3>

            {eligiblePractices &&
              eligiblePractices.length > 0 &&
              eligiblePractices.map((practice, index) => {
                const practiceTitle = practice.title.split("-")[0].trim();
                const currentData = eligiblePracticesData.find(
                  (data) => data.pnumber === practiceTitle
                );
                const currentPriorities = eligiblePracticesPriorities.find(
                  (data) => data.pnumber === practiceTitle
                );
                // const handlePracticeClick = () => {
                //   if (preferredPractice !== practiceTitle) {
                //     setPreferredPractice(practiceTitle);
                //     setPreferredSignups({
                //       G: currentData?.general,
                //       C: currentData?.continous,
                //     });
                //     setPreferredIncentives({
                //       S: currentData?.["32_5_p_sip"],
                //       R: currentData?.["20_p_rr_incentive"],
                //       P: currentData?.["50_p_pip"],
                //       C: currentData?.["climate_smrt_incentive"],
                //     });
                //   } else {
                //     setPreferredPractice(null);
                //   }
                // };
                const printPractice = practiceTitle;
                const printSignups = {
                  G: currentData?.general,
                  C: currentData?.continous,
                };
                const printIncentives = {
                  S: currentData?.["32_5_p_sip"],
                  R: currentData?.["20_p_rr_incentive"],
                  P: currentData?.["50_p_pip"],
                  C: currentData?.["climate_smrt_incentive"],
                };

                const getEstimatedPayment = (paymentMultiplier) => {
                  const totalFieldPayment = Math.round(
                    fieldRentalRate *
                      (printIncentives.S ? 1.325 : 1) *
                      (printIncentives.R ? 1.2 : 1) *
                      (printIncentives.P ? 1.5 : 1) *
                      (parseInt(printIncentives.C)
                        ? 1 + parseInt(printIncentives.C) / 100
                        : 1) *
                      paymentMultiplier
                  );
                  const maximumFieldPayment =
                    paymentMultiplier === 0.9
                      ? Math.round(300 * fieldSize)
                      : paymentMultiplier === 0.85
                      ? Math.round(240 * fieldSize)
                      : totalFieldPayment;
                  const estimatedFieldPayment =
                    totalFieldPayment <= maximumFieldPayment
                      ? totalFieldPayment
                      : maximumFieldPayment;

                  return estimatedFieldPayment;
                };

                return (
                  <div className="print report-practice-detail-container">
                    <PrintHelp
                      practice={practice}
                      currentData={currentData}
                      currentPriorities={currentPriorities}
                      selectedTopPracticeList={selectedTopPracticeList}
                      selectedFips={selectedFips}
                      listView="Compact"
                      preferredPractice={printPractice}
                      setPreferredPractice={setPreferredPractice}
                      setPreferredSignups={setPreferredSignups}
                      setPreferredIncentives={setPreferredIncentives}
                      userFavoritePractices={userFavoritePractices}
                      printView={true}
                    />

                    <div className="print report-practice-list-short">
                      <div className="print report-practice-detail">
                        <h3>
                          Your Estimated Field Rental Rate is{" "}
                          {printSignups.G && (
                            <span>
                              ${getEstimatedPayment(0.85)} (General Signup){" "}
                              <sup>[1]</sup>
                            </span>
                          )}
                          {printSignups.G && printSignups.C ? " or " : ""}
                          {printSignups.C && (
                            <span>
                              ${getEstimatedPayment(0.9)} (Continuous Signup){" "}
                              <sup>[1]</sup>
                            </span>
                          )}
                        </h3>
                        <div>
                          <p>
                            As determined by UDSA, landowners will receive
                            85% for general signup and 90% for continuous signup
                            of the estimated rental rate. By signing up for
                            <b>{printPractice}</b>,
                            {(preferredIncentives.S ||
                              preferredIncentives.R ||
                              preferredIncentives.P ||
                              !!parseInt(preferredIncentives.C)) && (
                              <span> you are eligible for </span>
                            )}
                            {preferredIncentives.S && (
                              <span>a 32.5% signup incentive, </span>
                            )}
                            {preferredIncentives.R && (
                              <span>a 20% rental rate incentive, </span>
                            )}
                            {preferredIncentives.P && (
                              <span>a 50% practice incentive, </span>
                            )}
                            {!!parseInt(preferredIncentives.C) && (
                              <span>
                                a {preferredIncentives.C}% Climate Smart
                                incentive,{" "}
                              </span>
                            )}
                            and{" "}
                            <b>{preferredPractice}</b> supports{" "}
                            {!preferredSignups.C ? (
                              <>
                                general signup, which has a maximum payment cap
                                set at $240/acre. This results in an estimated
                                potential first-year payment of{" "}
                                <b>${getEstimatedPayment(0.85)}</b> for
                                your field.
                              </>
                            ) : !preferredSignups.G ? (
                              <>
                                continuous signup, which has a maximum payment cap
                                set at $300/acre. This results in an estimated
                                potential first-year payment of{" "}
                                <b>${getEstimatedPayment(0.9)}</b> for
                                your field.
                              </>
                            ) : (
                              "both general and continuous signup."
                            )}
                            {preferredSignups.C && preferredSignups.G && (
                              <>
                                <span>
                                  {" "}
                                  For general signup, the cap is set at $240/acre,
                                  resulting in an estimated potential first-year
                                  payment of{" "}
                                  <b>
                                    ${getEstimatedPayment(0.85)}
                                  </b>{" "}
                                  for your field.
                                </span>
                                <span>
                                  {" "}
                                  For continuous signup, the cap is set at $300/acre,
                                  resulting in an estimated potential first-year
                                  payment of{" "}
                                  <b>
                                    ${getEstimatedPayment(0.9)}
                                  </b>{" "}
                                  for your field.
                                </span>
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          <hr className="report-hr" />
          <div className="report-general-info-container">
            <div>
              <h3
                className={
                  printList
                    ? "report-section-header print"
                    : "report-section-header"
                }
              >
                Local USDA Office:
              </h3>
              <div
                className={
                  printList
                    ? "report-usda-information print"
                    : "report-usda-information"
                }
              >
                <p>
                  Please reach out to your local USDA office for further
                  information.
                </p>
                <p>
                  {" "}
                  Find Your Local USDA Office by visiting
                  https://www.farmers.gov/working-with-us/service-center-locator
                </p>
              </div>
            </div>
            <div>
              <h3
                className={
                  printList
                    ? "report-section-header print"
                    : "report-section-header"
                }
              >
                Disclaimer:
              </h3>
              <div
                className={
                  printList ? "report-disclaimer print" : "report-disclaimer"
                }
              >
                <p>
                  [1] Please note that the estimated rental rate is a rough
                  estimation based on the CRP practice you selected. It may vary
                  due to your regional USDA official's final decision.
                </p>
                <p>
                  [2] This material is based upon work supported by the U.S.
                  Department of Agriculture, Farm Production and Conservation
                  program under agreement number FBC22CPT0012776. Any opinions,
                  findings, conclusions, or recommendations expressed in this
                  publication are those of the author(s) and do not necessarily
                  reflect the views of the U.S. Department of Agriculture. In
                  addition, any reference to specific brands or types of
                  products or services does not constitute or imply an
                  endorsement by the U.S. Department of Agriculture for those
                  products or services. USDA is an equal opportunity provider,
                  employer, and lender
                  (https://www.usda.gov/non-discrimination-statement). This work
                  is produced with support from the Mississippi State University
                  Forest and Wildlife Research Center and Mississippi State
                  University Geosystems Research Institute.
                </p>
                <p>
                  * All dollar amounts presented are approximations derived from
                  soil type and rental rate data available at the time of this
                  tool's publication.
                </p>
              </div>
            </div>
          </div>
          <Modal
            centered={true}
            title="Save My Search"
            open={isSavePopOpen}
            onOk={null}
            footer={null}
            onCancel={saveSearchPopClose}
          >
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              form={form}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={storeSearchData}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Save Search"
                name="SaveKey"
                rules={[
                  {
                    required: true,
                    message: "Please input your Save Search!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </Spin>
      <Footer />
    </>
  );
};

export default FieldPrint;
